<template>
    <div class="report">

        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Performance Report</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-4">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <select v-model="filter.client_id" class="form-control">
                                        <option value="" selected>-- Any Client --</option>
                                        <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group mr-1">
                                    <select v-model="filter.product_id" class="form-control">
                                        <option value="" selected>-- Any Product--</option>
                                        <option v-for="product in products" :key="product._id" :value="product.name">{{product.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group mr-1">
                                    <select v-model="filter.day" class="form-control">
                                        <option value="" selected>-- Filter --</option>
                                        <option value="1hour">Current hour</option>
                                        <option value="3hour">Last 3 hours</option>
                                        <option value="6hour">Last 6 hours</option>
                                        <option value="12hour">Last 12 hours</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="week">This Week</option>
                                        <option value="last_week">Last Week</option>
                                        <option value="month">This Month</option>
                                        <option value="last_month">Last Month</option>
                                        <option value="custom">Custom</option>
                                    </select>
                                </div>
                                <div v-if="filter.day === 'custom'" class="form-group mr-1">
                                    <input type="date" v-model="from" class="form-control date" placeholder="From"/>
                                </div>
                                <div v-if="filter.day === 'custom'" class="form-group mr-1">
                                    <input type="date" v-model="to" class="form-control date" placeholder="To"/>
                                </div>
                                <button
                                    type="button"
                                    class="btn text-white mr-1"
                                    @click="reports"
                                    style="background-color: #383838"
                                >
                                    <i class="fa fa-search" title="Search"></i>
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div  class="row stats mb-4">
                    <div class="col-12 col-sm-4">
                        <div class="card">
                            <div class="card-body text-center">
                                <!--                            <h4>{{Number(report.stats.today||0).toLocaleString()}} hits</h4>-->
                                <h4>{{total.toLocaleString()}} hits</h4>
                                <h5>Total</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row hours mb-4">
                    <div class="col-12">
                        <div class="chart">
                            <line-chart :data="report.chartdata.data" area />
                        </div>
                    </div>
                    <!--<div class="col-12 col-sm-2 mb-1" v-for="(hour,x) in report.hours" v-bind:key="x">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4>{{Number(hour||0).toLocaleString()}} hits</h4>
                                <h5>{{x}} hour</h5>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>




        <!--        <div class="listing pt-3">
                    <table class="table table-hover table-responsive table-striped" style="display: table;">
                        <thead>
                        <tr>
                            <td>Campaign ID</td>
                            <td>Provider</td>
                            <td>IP</td>
                            <td>State</td>
                            <td>City</td>
                            <td>Date</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in items.data" v-bind:key="data._id">
                            <td>{{data.campaign_id}}</td>
                            <td>{{data.proxy ? data.proxy.provider : 'N/A'}}</td>
                            <td>{{data.proxy ? data.proxy.ip : 'N/A'}}</td>
                            <td>{{data.proxy ? data.proxy.location ? data.proxy.location.state : data.proxy.state : 'N/A'}}</td>
                            <td>{{data.proxy ? data.proxy.location ? data.proxy.location.city : data.proxy.city : 'N/A'}}</td>
                            <td>{{ data.date | userTime(user.timezone)}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <v-pagination
                                v-model="page"
                                :pageCount="Math.ceil(items.total/max)"
                                :classes="bootstrapPaginationClasses"
                                :labels="customLabels"
                                @input="logs"></v-pagination>
                        </div>
                        <div class="col-12 col-sm-3 text-right">
                            <span v-if="items.data">{{items.data.length * Number(page||1)}}</span> of <span>{{items.total}}</span>
                        </div>
                    </div>
                </div>-->
    </div>
</template>

<script>
import {LineChart} from "dr-vue-echarts";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment-timezone";
//import vPagination from 'vue-plain-pagination';

export default {
    name: "Performance.Report",
    props:['user'],
    components: {
        LineChart,
        //vPagination
    },
    data: function () {
        return {
            clients:[],
            item: {
                geo:{},
                timezone: ''
            },
            total:0,
            customerQuery:'',
            action:{mobile:'',status:'',method:''},
            selectedCamp:[],
            items: {total:0, data:[]},
            editor: false,
            countries:[],
            states:[],
            cities:[],
            selected:{},
            report: {stats: {}, chartdata: {}},
            geos:{},
            filter: {
                day: '1hour',
                client_id: '',
                product_id: '',
            },
            dropdownClients: [],
            products: [],
            from: new Date(),
            to: new Date(),
            max: 25,
            page: 1,
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            searching: false,
            reportLoading:false
        }
    },
    created() {
        this.reports();
        //this.logs();
        this.GetClientsList();
        this.loadProducts();
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.reports(this.report);
            }
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        reports: function(obj){
            this.total = 0;
            this.searching = true;
            var request = {'apikey': this.$root.apikey, filter: this.filter, to: this.to, from: this.from}
            //this is use to store the time format so that the time requested can match the time return from the server
            //only used when hourly is selected
            let timeStamp = [
                {label:'12:00 AM',value:"12 am"},{label:'1:00 AM',value:"1 am"}, {label:'2:00 AM',value:"2 am"},
                {label:'3:00 AM',value:"3 am"}, {label:'4:00 AM',value:"4 am"},{label:'5:00 AM',value:"5 am"},
                {label:'6:00 AM',value:"6 am"},{label:'7:00 AM',value:"7 am"},{label:'8:00 AM',value:"8 am"},
                {label:'9:00 AM',value:"9 am"},{label:'10:00 AM',value:"10 am"},{label:'11:00 AM',value:"11 am"},
                {label:'12:00 PM',value:"12 pm"},{label:'1:00 PM',value:"1 pm"}, {label:'2:00 PM',value:"2 pm"},
                {label:'3:00 PM',value:"3 pm"}, {label:'4:00 PM',value:"4 pm"},{label:'5:00 PM',value:"5 pm"},
                {label:'6:00 PM',value:"6 pm"},{label:'7:00 PM',value:"7 pm"},{label:'8:00 PM',value:"8 pm"},
                {label:'9:00 PM',value:"9 pm"},{label:'10:00 PM',value:"10 pm"},{label:'11:00 PM',value:"11 pm"} ]
            this.reportLoading = true
            this.graph = true;
            let start = '';

            axios.get(`${this.$root.serverUrl}/admin/reports/performance`, {params:request}).then((resp)=>{
                //Store the stats
                this.report = Object(obj, {});
                if(this.filter.day === '1hour') start = moment().startOf('hour');
                if(this.filter.day === '3hour') start = moment().subtract(3, 'hours').startOf('hour');
                if(this.filter.day === '6hour') start = moment().subtract(6, 'hours').startOf('hour');
                if(this.filter.day === '12hour') start = moment().subtract(12, 'hours').startOf('hour');
                this.report.stats = resp.data.data;
                let a;
                let b;
                if(this.report.stats.type === 'hour') {
                    this.report.chartdata = {data:[{name: this.report.name, "x-name": "Hour", "y-name": "Hits", data: []}]};
                    for(var i in this.report.stats.hourly){
                        this.report.chartdata.data[0].data.push({label: `${i}`, value:this.report.stats.hourly[i]})
                        this.total+=this.report.stats.hourly[i]
                    }
                } else {
                    this.report.chartdata = {data:[{name: this.report.name, "x-name": "Day", "y-name": "Hits", data: []}]};
                    for(var v in this.report.stats.daily){
                        this.report.chartdata.data[0].data.push({label: `${v}`, value:this.report.stats.daily[v]})
                        this.total+=this.report.stats.daily[v]
                    }

                }


                // breaking up the array into two separate array based on the selected data period.
                //this code finds the index of which the time is found splits the array to start from the time request
                // Example if 12 hours is selected and the time is 12pm the array will be split at what ever index
                // 12 is found at and rejoin with the remaining index.
                // [12,1,2,3,4,5am,6,7,8,9,10,11,12,1,2,3,4,5pm,6,7,8,9,10,11], let say 5am i selected
                // then the array will now slit into a new array from 5am to 11 pm -
                // a[5am,6,7,8,9,10,11,12,1,2,3,4,5pm,6,7,8,9,10,11]
                // and b[12am,1,2,3,4,] from 12 am - 4am
                // the new array will look like this
                // new array = [5am,6,7,8,9,10,11,12,1,2,3,4,5pm,6,7,8,9,10,11,12am,1,2,3,4,]

                if(start) {
                    a = this.report.chartdata.data[0].data
                    let timeLocate = timeStamp.map(function (e) {return e.label}).indexOf(start.format('LT'))
                    let pos = this.report.chartdata.data[0].data.map(function (e) {return e.label}).
                    indexOf(timeStamp[timeLocate].value)
                    b = a.splice(0, pos)
                    this.report.chartdata.data[0].data = [].concat(a, b) // new array
                }

                this.graph = false;
                this.reportLoading = false
                this.searching = false;
                this.$root.preloader = false
            }).catch((err)=>{
                this.graph = false;
                this.reportLoading = false
                this.searching = false;
                this.$root.preloader = false
                console.log('report.error', err)
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            })
        },
        logs: function(){
            var page;
            if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                //Pagination triggered event
                console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                page = (this.page)? ((this.page == 1) ? 0 : Number(this.page-1) ) : 0
            } else
                page = 0

            var request = {'apikey': this.$root.apikey, filter: this.filter, to: this.to, from: this.from, page: page, max: this.max}
            this.$root.preloader = true

            axios.get(`${this.$root.serverUrl}/admin/reports/logs`, {params:request}).then((resp)=>{
                this.items = resp.data;
                if(!this.graph) this.$root.preloader = false
            }).catch((err)=>{
                console.log('report.error', err)
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            })
        },
    }

}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
</style>

